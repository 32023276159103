<template>
  <carousel
      class="ms-8 me-8"
      :perPageCustom="[[1,1],[450,1],[768, 2], [1280, 3]]"
      :navigationEnabled="true"
      :paginationActiveColor="'#6D7E6D'"
      :paginationColor="'#B19983'"
  >
    <slide
        v-for="wedding of weddings"
        :key="wedding.id"
        class="pa-2 pb-8">
      <v-card max-width="100vw" @click="openWedding(wedding.id)" class="rounded-xl " style="background-color: #F3F4F3" flat>
        <v-row>
          <v-col cols="12" class="">
            <v-card-title>
              <h5 class="text-center" style="width: 100%">
                {{ wedding.customerFirstName }} <br>
                <v-icon class="pe-1">mdi-ring</v-icon>
                <br>{{ wedding.customerPartnerName }}
              </h5>
            </v-card-title>
            <v-card-text>
              <b>{{ wedding.values.status }}</b>
              <br>
              Am
              <b v-if="!wedding.wedDate">AUSSTEHEND</b>
              <b>{{ getStringDate(wedding.wedDate) }}</b>
            </v-card-text>
            <v-card-actions class="">
              <v-spacer></v-spacer>
              <a @click="openWedding(wedding.id)">ANSEHEN</a>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </slide>
  </carousel>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
import {date2String} from "@/helper/helper";


export default {
  name: "Message",
  components:{
    Carousel,
    Slide,
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    weddings: {
      type: Array,
      default(){
        return []
      }
    },
  },
  methods:{
    openWedding(id){
      this.$emit('openWedding', id)
    },
    getStringDate(date) {
      return date2String(date)
    },
  }
}
</script>
